import React from 'react'

import Carousel from '../carousel'
import { ImageBanner } from '../banner'
import CardCarousel from '../card/card-carousel'
import CardPanel from '../card/panel'
import CommentReview from '../comment-review/comment-review'
import PricingTable from '../pricing/pricing'
import DownloadAppsPanel from '../download-apps-panel/download-apps-panel'
import {
  BoxDetail,
  BoxDetailExampleCarousel,
  BoxWrapper,
  TextNoData,
} from '../box'

const style1 = { marginTop: 10, width: '100%' }
const style2 = { textAlign: 'center', padding: '30px 20px 60px 20px' }
const style3 = { textAlign: 'center', padding: '60px 20px 60px 20px' }
const style4 = { width: '100%' }

export default class HomeNonLogin extends React.Component {
  constructor(props) {
    super(props)

    let width = document.documentElement.clientWidth
    let textBtn =
      width <= 800
        ? this.props.locale.START_YOUR_FIRST_EVENT
        : this.props.locale.JOIN_FOR_FREE

    this.state = {
      text: textBtn,
    }
  }

  display(props) {
    return (
      <BoxWrapper>
        {props.data.slider_show.length > 0 && (
          <Carousel showEveryTime className="is-banner-slider">
            {props.data.slider_show.map(
              (v, i) =>
                v.img_url && (
                  <div key={`SliderShow-item-${i}`}>
                    <ImageBanner src={v.img_url} alt="" />
                  </div>
                )
            )}
          </Carousel>
        )}

        <div style={style3}>
          {/*<h2 className="header-1">{props.locale.EASILY_CREATE}</h2>*/}
          <button className="btn-1" onClick={props.onJoinFree}>
            {this.state.text}
          </button>
        </div>

        <BoxDetailExampleCarousel>
          <BoxDetail>
            <CardPanel
              title={props.locale.LATEST_EVENTS}
              locale={this.props.locale}
            >
              {props.data.cards.length > 0 && (
                <CardCarousel
                  className="is-card-slider"
                  data={props.data.cards}
                  dataMobile={props.data.cardsMobile}
                  onShare={id => props.onShare(id)}
                  onGoDetail={id => props.onGoDetail(id)}
                  locale={this.props.locale}
                />
              )}
              {props.data.cards.length <= 0 && (
                <div style={style1}>
                  <TextNoData>{this.props.locale.NO_EVENT}</TextNoData>
                </div>
              )}
            </CardPanel>
          </BoxDetail>
        </BoxDetailExampleCarousel>

        <img
          src={
            this.props.lang === 'TH'
              ? process.env.GATSBY_IMG_SAVE_TIME_TH
              : process.env.GATSBY_IMG_SAVE_TIME_EN
          }
          alt=""
          style={style4}
        />

        {props.dataPrcing && (
          <PricingTable data={props.dataPrcing} locale={props.locale} />
        )}

        {props.data.comment_review.length > 0 && (
          <CommentReview data={props.data.comment_review} />
        )}

        <DownloadAppsPanel locale={props.locale} />

        <div className="is-mobile" style={style2}>
          <button className="btn-1" onClick={props.onJoinFree}>
            {this.state.text}
          </button>
        </div>
      </BoxWrapper>
    )
  }

  render() {
    return this.display(this.props)
  }
}
