import React from 'react'
import Carousel from '../carousel'
import { BoxDetail } from '../box'

import Star1 from '../../images/star_1.svg'
import Star2 from '../../images/star_2.svg'
import Star3 from '../../images/star_3.svg'

/* eslint-disable no-undef */
const styleStar = { width: 20, height: 20 }

const isFloat = n => Number(n) === n && n % 1 !== 0

function renderStar(rate) {
  const rate2 = Math.round(5 - rate)
  const isStar3 = isFloat(rate)
  const elem = []
  for (let i = 1; i <= rate; i++) {
    elem.push(
      <li key={`star1-${i}`}>
        <img src={Star1} alt="" style={styleStar} />
      </li>
    )
  }
  for (let i = 1; i <= rate2; i++) {
    if (i === 1 && isStar3) {
      elem.push(
        <li key={`star2-${i}`}>
          <img src={Star3} alt="" style={styleStar} />
        </li>
      )
    } else {
      elem.push(
        <li key={`star2-${i}`}>
          <img src={Star2} alt="" style={styleStar} />
        </li>
      )
    }
  }
  return elem
}

export default class CommentReviewComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewMode: 'PC',
    }
    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  resize() {
    const w = document.documentElement.clientWidth
    if (w <= 800) {
      this.setState({ viewMode: 'MOBILE' })
    } else {
      this.setState({ viewMode: 'PC' })
    }
  }

  style1 = { padding: 15 }
  style2 = { marginTop: 10, marginBottom: 10 }
  style3 = { marginTop: 15 }

  displayBox(v, i, index) {
    if (v.enabled) {
      return (
        <div
          className="col-md-3"
          style={this.style1}
          key={`comment-review-item-${index}-${i}`}
        >
          <img
            src={
              v.picture
                ? v.picture + '?sz=m'
                : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO
            }
            className="comment-review-user"
            alt=""
          />
          <ul className="rating-panel" style={this.style2}>
            {renderStar(parseInt(v.rating) * 0.5)}
          </ul>
          <p>“{v.message}”</p>
          <p style={this.style3}>- {v.name} -</p>
        </div>
      )
    } else {
      return null
    }
  }

  renderPC(props) {
    return (
      <BoxDetail className="comment-review">
        <Carousel loop={false} autoplay={false} className="is-card-slider">
          {props.data.map((v, i) => (
            <div className="row" key={`pc-comment-${i}`}>
              {v.map((v2, i2) => this.displayBox(v2, i2, i))}
            </div>
          ))}
        </Carousel>
      </BoxDetail>
    )
  }

  renderMobile(props) {
    return (
      <div className="row no-gutters comment-review">
        <Carousel loop={false} autoplay={false} isMobile>
          {props.data.map((v, i) =>
            v.map((v2, i2) => (
              <div className="row" key={`mobile-comment-${i}-${i2}`}>
                {this.displayBox(v2, i2, i)}
              </div>
            ))
          )}
        </Carousel>
      </div>
    )
  }

  render() {
    if (this.state.viewMode === 'PC') {
      return this.renderPC(this.props)
    } else if (this.state.viewMode === 'MOBILE') {
      return this.renderMobile(this.props)
    } else {
      return null
    }
  }
}
