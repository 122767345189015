import React from 'react'

function renderDataList(row, rowIndex) {
  const ele = []
  row.datas.forEach((v, i) => {
    if (i === 0) {
      ele.push(
        <td className="price-td-first" key={`pricing-data-${rowIndex}-${i}`}>
          {v}
        </td>
      )
    } else {
      if (row.type === 'integer') {
        ele.push(
          <td
            key={`pricing-data-${rowIndex}-${i}`}
            style={{ textAlign: 'center' }}
          >
            {v}
          </td>
        )
      } else if (row.type === 'bool') {
        if (v) {
          ele.push(
            <td
              key={`pricing-data-${rowIndex}-${i}`}
              style={{ textAlign: 'center' }}
            >
              <i className="fa fa-check" />
            </td>
          )
        } else {
          ele.push(
            <td
              key={`pricing-data-${rowIndex}-${i}`}
              style={{ textAlign: 'center' }}
            >
              <i className="fa fa-times" />
            </td>
          )
        }
      }
    }
  })
  return ele
}

export default props => {
  if (props.data.data_head.length <= 0) {
    return null
  }
  return (
    <div className="price-warpper" id="price-table">
      <div className="price-panel">
        <h3>Features, Price and Plans</h3>
        <table>
          <thead>
            <tr>
              {props.data.data_head.map((v, i) => (
                <th key={`pricing-head-${i}`} style={{ textAlign: 'center' }}>
                  {v}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.data_list.map((row, rowIndex) => (
              <tr key={`pricing-data-${rowIndex}`}>
                {renderDataList(row, rowIndex)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
